<template>
  <div v-if="dialogVisible">
    <el-dialog :visible.sync="dialogVisible" custom-class="detail_dialog" :top="'2vh'" :fullscreen="isExpand"
      :show-close="false" :before-close="handleClose" :destroy-on-close="true">
      <template slot="title">
        <div class="title-box">
          <span>报价</span>
          <span class="icon-box">
            <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand">
            <i class="el-icon-close" @click="handleClose"></i>
          </span>
        </div>
      </template>
      <el-form ref="form" :model="form" :rules="rules" class="form-box">
        <el-row>
          <el-col :span="8">
            <el-form-item label="供应商名称" label-width="8em">{{ userInfo.tenantName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" label-width="8em">{{ form.contactName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" label-width="8em">{{ form.contactPhone }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="付款方式" prop="paymentMethod" label-width="8em">
              <el-select v-model="form.paymentMethod" placeholder="请选择付款方式" clearable>
                <el-option v-for="opt in paymentMethodOptions" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发票类型" prop="invoiceType" label-width="8em">
              <el-select v-model="form.invoiceType" placeholder="请选择发票类型" clearable>
                <el-option v-for="opt in InvoiceTypeEnum" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="税率" prop="taxRate" label-width="8em">
              <el-select v-model="form.taxRate" placeholder="请选择税率" clearable>
                <el-option v-for="opt in taxRateOptions" :key="opt.optionCode" :label="opt.optionName"
                  :value="opt.optionName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="交付方式" prop="deliveryMethod" label-width="8em">
              <el-select v-model="form.deliveryMethod" placeholder="请选择交付方式" clearable>
                <el-option v-for="opt in deliveryMethodOptions" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划上门时间" prop="plannedDeliveryDate" label-width="8em">
              <el-date-picker v-model="form.plannedDeliveryDate" type="datetime" placeholder="请选择计划上门时间"
                value-format="timestamp" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保修期" prop="warrantyPeriod" label-width="8em">
              <div style="display: flex;">
                <el-input-number class="number-input" size="small" v-model="form.warrantyPeriod" :precision="2"
                  :step="1" :min="0" placeholder="请输入保修期" clearable></el-input-number>
                <el-select v-model="form.warrantyPeriodUnitName" placeholder="请选择"
                  style="width:100px; margin-left: 6px;" clearable>
                  <el-option :label="i.optionName" :value="i.optionName" v-for="i in warrantyPeriodUnitOptions"
                    :key="i.optionCode"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="是否含备用机" prop="containSpareMachineFlag" label-width="8em">
              <el-select v-model="form.containSpareMachineFlag" placeholder="请选择是否含备用机" clearable>
                <el-option label="不包含" :value="false"></el-option>
                <el-option label="包含" :value="true">包含</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="报价备注" prop="quotePriceRemark" label-width="8em">
              <el-input type="textarea" :rows="3" placeholder="请输入报价备注" :maxlength="200" v-model="form.quotePriceRemark"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="报价附件" prop="inquireQuotePriceAnnexList" label-width="8em">
              <Upload v-model="form.inquireQuotePriceAnnexList" label="回单" type="single"
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/png,image/jpeg,image/jpg,application/pdf"
                @remove="handleRemove" :maxSize="10" size="origin" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="检测结果" prop="detectionResult" label-width="8em">
              <el-input type="textarea" :rows="3" placeholder="请输入检测结果" :maxlength="200" v-model="form.detectionResult"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="table-wrapper el-form-wrapper">
          <el-row style="margin-bottom: 12px">
            <span style="font-size: 14px; color: #1F2733; font-weight: 600;margin-right: 16px;">报价明细</span>
            <el-button type="primary" @click="handleAdd">新增</el-button>
            <el-button type="info" plain @click="importByExcel">导入</el-button>
          </el-row>
          <el-table ref="table" border :data="form.itemList" header-row-class-name="table-label">
            <el-table-column type="index" width="50" label="序号">
            </el-table-column>
            <el-table-column v-for="e in tableConfig" :key="e.key" :prop="e.key" :width="e.width" :label="e.title"
              :show-overflow-tooltip="e.ellipsis">
              <template #header>
                <span v-if="e.rules || e.setRules"
                  style="color: #F56C6C;margin-right: 4px;font-family: SimSun, sans-serif;">*</span>{{ e.title }}
                <el-tooltip v-if="e.tooltip" popper-class="quotePriceDialog__tooltip" effect="dark"
                  :content="e.tooltip.content" placement="top">
                  <i class="el-icon-warning"></i>
                </el-tooltip>
              </template>
              <template slot-scope="{row, $index: index}">
                <template v-if="e._type === 'text'">{{ row[e.key] }}</template>
                <el-form-item :prop="'itemList.' + index + '.' + e.key"
                  :rules="e.setRules ? e.setRules(row) : e.rules || []">
                  <div style="display: flex; align-items: center">
                    <template v-if="e._type === 'input'">
                      <el-input v-if="!e.isText(row)" :disabled="e.disabled" v-model="row[e.key]"
                        :placeholder="`请输入${e.title}`" :style="{ width: e.inputWidth }" clearable />
                      <template v-else>{{ row[e.key] || '--' }}</template>
                    </template>
                    <template v-if="e._type === 'number'">
                      <el-input-number v-if="!e.isText(row)" :disabled="e.disabled" v-model="row[e.key]" :min="e.min"
                        :step="e.step" :precision="e.precision" :placeholder="`请输入${e.title}`" clearable
                        @change="() => e.handleChange(row)" />
                      <template v-else>{{ row[e.key] || '--' }}</template>
                    </template>
                    <template v-if="e._type === 'select'">
                      <el-select v-if="!e.isText(row)" :disabled="e.disabled" v-model="row[e.key]"
                        :placeholder="`请选择${e.title}`" @change="v => e.handleChange(v, row)" clearable>
                        <el-option v-for="opt in e._options" :key="opt.value" :label="opt.label" :value="opt.value">
                        </el-option>
                      </el-select>
                      <template v-else>{{ row[e.key] || '--' }}</template>
                    </template>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-popconfirm confirm-button-text="确认" cancel-button-text="取消" title="确认删除？"
                  @confirm="handleDelete(scope.$index)">
                  <a slot="reference" style="color: #f56c6c; cursor: pointer;">删除</a>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>

          <el-table border style="margin-top: 10px;" :data="billData" :span-method="arraySpanMethod"
            header-row-class-name="table-label">
            <el-table-column prop="name" label="计费项目" width="180">
            </el-table-column>
            <el-table-column prop="money" label="金额（元）" width="180">
              <template slot-scope="{row, $index: index}">
                <el-input-number v-if="index === 2" class="number-input" size="small" v-model="row.money" :precision="2"
                  :step="1" :min="0" placeholder="请输入" clearable @change="handleMoneyChange"></el-input-number>
                <span v-else>
                  {{ row.money ? row.money.toLocaleString() : '' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="合计（元）">
              <template slot-scope="{row}">
                <span>
                  {{ row.total ? row.total.toLocaleString() : '' }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="handlePreview">预览报价单</el-button>
        <el-button type="info" plain @click="handleClose">取消</el-button>
        <el-button type="info" plain @click="() => handleSave(true)">保存草稿</el-button>
        <el-button type="primary" @click="() => handleSave(false)">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog top="5vh" :visible.sync="isImportByExcel" title="导入" width="66%" :close-on-click-modal="false"
      :destroy-on-close="true">
      <ExcelHandle :config="tableConfig" :downloadUrl="downloadUrl" :handleDownloadData="handleDownloadData"
        @cancel="cancelExcel" @confirm="confirmExcel">
      </ExcelHandle>
    </el-dialog>
    <el-dialog :visible.sync="printDialogVisible" class="printDialog" top="5vh">
      <div slot="title" style="font-family: PingFangSC, PingFang SC;font-weight: 600;font-size: 14px;color: #1F2733;">
        预览报价单
      </div>
      <PrintTemplate :config="printConfig"></PrintTemplate>
      <template #footer>
        <el-button type="primary" @click="handlePrint">打印</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import InvoiceTypeEnumMap, { InvoiceTypeEnum } from '@/enums/InvoiceTypeEnum';
import { inquireQuotePriceAdd, inquireQuotePriceUpdate, inquireQuotePriceNewestDetail, dictionaryData } from '@/api/inquiryForm.js';
import ExcelHandle from '@/components/eleExcelHandle/index.vue';
import PrintTemplate from '@/components/printTemplate/index.vue';
import * as Moment from 'dayjs';
import { numberToChinese } from '@/utils/number';

const operationTypeMap = {
  MAINTAIN: '维修',
  '维修': 'MAINTAIN',
  TEST: '测试',
  '测试': 'TEST',
  CHANGE: '更换',
  '更换': 'CHANGE',
};

const compatibilityRequirementMap = {
  1: '原厂全新',
  '原厂全新': 1,
  2: '兼容',
  '兼容': 2,
  3: '原厂兼容',
  '原厂兼容': 3,
};

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    orderDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { ExcelHandle, PrintTemplate },
  computed: {
    tableConfig () {
      return [
        {
          title: '备件编码',
          key: 'code',
          width: 160,
          ellipsis: true,
          _type: 'input',
          isText: (row) => {
            return ['维修', '测试'].includes(row.operationType);
          },
        },
        {
          title: '备件名称',
          key: 'name',
          width: 160,
          ellipsis: true,
          _type: 'input',
          setRules (row) {
            return [{ required: !['维修', '测试'].includes(row.operationType), message: '请输入备件名称', trigger: 'blur' }];
          },
          isText: (row) => {
            return ['维修', '测试'].includes(row.operationType);
          },
        },
        {
          title: '备件型号',
          key: 'model',
          width: 160,
          ellipsis: true,
          _type: 'input',
          setRules (row) {
            return [{ required: !['维修', '测试'].includes(row.operationType), message: '请输入备件型号', trigger: 'blur' }];
          },
          isText: (row) => {
            return ['维修', '测试'].includes(row.operationType);
          },
        },
        {
          title: '操作内容',
          key: 'operationType',
          width: 150,
          ellipsis: true,
          _type: 'select',
          _options: [
            {
              label: '维修',
              value: '维修',
            },
            {
              label: '测试',
              value: '测试',
            },
            {
              label: '更换',
              value: '更换',
            },
          ],
          rules: [{ required: true, message: '请选择操作内容', trigger: 'change' }],
          handleChange: (v, row) => {
            if (['维修', '测试'].includes(row.operationType)) {
              let money = '';

              if (row.quantity && row.price) {
                money = row.quantity * row.price;
              } else {
                money = '';
              }

              this.$set(row, 'code', '');
              this.$set(row, 'name', '');
              this.$set(row, 'model', '');
              this.$set(row, 'compatibilityRequirement', '');
              this.$set(row, 'quantity', 1);
              this.$set(row, 'unitName', '次');
              this.$set(row, 'money', money);
            }

            if (!this.isImportByExcel) {
              this.calcBill();
            }
          },
          tooltip: {
            content: '操作内容选择【维修】或【测试】填写金额后会自动计入人工费用；操作内容选择【更换】填写金额后会自动计入备件费用',
          },
          isText: () => {
            return false;
          },
        },
        {
          title: '原厂/兼容',
          key: 'compatibilityRequirement',
          width: 150,
          ellipsis: true,
          _type: 'select',
          _options: [
            {
              label: '原厂全新',
              value: '原厂全新',
            },
            {
              label: '兼容',
              value: '兼容',
            },
            {
              label: '原厂兼容',
              value: '原厂兼容',
            },
          ],
          setRules (row) {
            return [{ required: !['维修', '测试'].includes(row.operationType), message: '请选择', trigger: 'change' }];
          },
          handleChange: () => { },
          isText: (row) => {
            return ['维修', '测试'].includes(row.operationType);
          },
        },
        {
          title: '数量',
          key: 'quantity',
          width: 180,
          ellipsis: true,
          _type: 'number',
          min: 0,
          step: 1,
          precision: 0,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value && value !== 0) {
                  callback(new Error('请输入数量'));
                } else if (value === 0) {
                  callback(new Error('数量不能为0'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          handleChange: (row) => {
            if (row.quantity && row.price) {
              row.money = row.quantity * row.price;
            } else {
              row.money = '';
            }

            if (!this.isImportByExcel) {
              this.calcBill();
            }
          },
          isText: (row) => {
            return ['维修', '测试'].includes(row.operationType);
          },
        },
        {
          title: '单位',
          key: 'unitName',
          width: 160,
          ellipsis: true,
          _type: 'select',
          _options: this.measureUnitOptions.map(q => ({
            label: q.optionName,
            value: q.optionName,
          })),
          rules: [{ required: true, message: '请选择单位', trigger: 'change' }],
          handleChange: () => { },
          isText: (row) => {
            return ['维修', '测试'].includes(row.operationType);
          },
        },
        {
          title: '单价（元）',
          key: 'price',
          width: 160,
          ellipsis: true,
          _type: 'number',
          min: 0,
          step: 0.01,
          precision: 2,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value && value !== 0) {
                  callback(new Error('请输入单价'));
                } else if (value === 0) {
                  callback(new Error('单价不能为0'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          handleChange: (row) => {
            if (row.quantity && row.price) {
              row.money = parseInt((row.quantity * 100) * (row.price * 100)) / 10000;
            } else {
              row.money = '';
            }

            if (!this.isImportByExcel) {
              this.calcBill();
            }
          },
          isText: () => {
            return false;
          },
        },
        {
          title: '金额（元）',
          key: 'money',
          width: 160,
          ellipsis: true,
          _type: 'text',
        },
      ];
    },
  },
  watch: {
    status: {
      handler () {
        this.getDetail();
      },
      immediate: true,
    },
  },
  data () {
    return {
      printDialogVisible: false,
      isImportByExcel: false,
      InvoiceTypeEnum,
      isExpand: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      activeName: 'order',
      form: {
        id: undefined,
        contactName: '',
        contactPhone: '',
        paymentMethod: '',
        invoiceType: '',
        taxRate: '',
        deliveryMethod: '',
        plannedDeliveryDate: '',
        warrantyPeriod: undefined,
        warrantyPeriodUnitName: '',
        containSpareMachineFlag: '',
        quotePriceRemark: '',
        inquireQuotePriceAnnexList: [],
        detectionResult: '',
        itemList: [],
        labourAmount: '',
        sparePartAmount: '',
        transportationAmount: '',
      },
      userInfo: {},
      paymentMethodOptions: [
        {
          label: '货到付款',
          value: 1,
        },
        {
          label: '预付款',
          value: 2,
        },
        {
          label: '票到付款',
          value: 3,
        },
        {
          label: '账期付款',
          value: 4,
        },
      ],
      deliveryMethodOptions: [
        {
          label: '上门',
          value: 'HOME',
        },
        {
          label: '快递',
          value: 'EXPRESS',
        },
        {
          label: '自提',
          value: 'SELF_FETCH',
        },
        {
          label: '物流',
          value: 'LOGISTICS',
        },
      ],
      taxRateOptions: [],
      warrantyPeriodUnitOptions: [],
      rules: {
        paymentMethod: [
          { required: true, message: '请选择付款方式', trigger: 'change' },
        ],
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        taxRate: [
          { required: true, message: '请选择税率', trigger: 'change' },
        ],
        deliveryMethod: [
          { required: true, message: '请选择交付方式', trigger: 'change' },
        ],
        plannedDeliveryDate: [
          { required: true, message: '请选择计划上门时间', trigger: 'change' },
        ],
        warrantyPeriod: [
          { required: false, message: '请输入保修期', trigger: 'blur' },
        ],
        containSpareMachineFlag: [
          { required: true, message: '请选择是否含备用机', trigger: 'change' },
        ],
      },
      billData: [
        {
          name: '人工',
          money: '',
          total: '',
        },
        {
          name: '备件',
          money: '',
          total: '',
        },
        {
          name: '运费及其它',
          money: undefined,
          total: '',
        },
      ],
      downloadUrl: { // to do
        name: '报价_维修模版',
        url: `${process.env.VUE_APP_HOST}10000000000/%E6%8A%A5%E4%BB%B7_%E7%BB%B4%E4%BF%AE%E6%A8%A1%E7%89%88.xlsx`,
      },
      measureUnitOptions: [],
      printConfig: {
        printId: 'queryPrice',
        title: '报价单',
        logo: require('@/assets/logo.svg'),
        contentConfig: [],
      },
    };
  },
  methods: {
    formatDate (text) {
      return text ? Moment(text).format('YYYY-MM-DD') : '';
    },
    formatNumberWithCommasAndTwoDecimals (num) {
      if (!num && num !== 0) {
        return '';
      }

      let numStr = num.toFixed(2);

      let [integerPart, decimalPart] = numStr.split('.');

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return integerPart + (decimalPart ? '.' + decimalPart : '');
    },
    getDesc (key) {
      let ret = '';

      if (key === 'quantity') {
        ret = `${this.orderDetail?.deviceList?.[0].quantity}${this.orderDetail?.deviceList?.[0].unitName}` || '';
      } else {
        ret = this.orderDetail?.deviceList?.[0][key] || '';
      }
      return ret;
    },
    handlePrint () {
      const style
        = '@page { margin: 0; } @media print { * { font-size: 12pt; background-color: red;} body { margin: 1cm; }}';

      this.$print({
        printable: this.printConfig.printId,
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
    handlePrintInfo () {
      const content = {
        type: 'table',
        colCount: 19,
        rows: [],
      };

      content.rows.push(
        {
          cols: [
            {value: '客户名称', colSpan: 3},
            {value: this.orderDetail.inquirePriceParty, colSpan: 16},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '地址', colSpan: 3},
            {value: this.$route.name === 'RECOMMEND_BUSINESS_OPPORTUNITIES'
              ? `${this.orderDetail.deliveryProvinceName || ''}${this.orderDetail.deliveryCityName
          || ''}` : `${this.orderDetail.deliveryProvinceName || ''}${this.orderDetail.deliveryCityName
            || ''}${this.orderDetail.deliveryAreaName || ''}${this.orderDetail.deliveryDetailAddress || ''}`,
            colSpan: 10},
            {value: '交付日期', colSpan: 2},
            {value: this.formatDate(this.form.plannedDeliveryDate), colSpan: 4},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '联系人', colSpan: 3},
            {value: this.form.contactName, colSpan: 2},
            {value: '手机', colSpan: 2},
            {value: this.form.contactPhone, colSpan: 2},
            {value: '电子邮箱', colSpan: 2},
            {value: '', colSpan: 2},
            {value: '邮编', colSpan: 2},
            {value: '', colSpan: 4 },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '产品信息', colSpan: 3, rowSpan: 2, style: { padding: '1em 0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {value: '产品名称', colSpan: 2},
            {value: this.getDesc('deviceName'), colSpan: 2},
            {value: '品牌', colSpan: 2},
            {value: this.getDesc('deviceBrandName'), colSpan: 2},
            {value: '型号', colSpan: 2},
            {value: this.getDesc('deviceModel'), colSpan: 2},
            {value: '编号', colSpan: 1},
            {value: this.getDesc('deviceSerialNumber'), colSpan: 3},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '外置附件', colSpan: 2},
            {value: '', colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '检测信息', colSpan: 3, rowSpan: 3, style: { padding: '0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {value: '主诉故障现象', colSpan: 2},
            {value: this.getDesc('faultDescription'), colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '检测结果', colSpan: 2},
            {value: this.form.detectionResult, colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '备注', colSpan: 2},
            {value: this.form.quotePriceRemark, colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '维护维修及更换零配件情况', colSpan: 19, style: { fontWeight: 'bold', background: '#f5f5f5' }},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '序号', colSpan: 3},
            {value: '零配件名称', colSpan: 3},
            {value: '操作内容', colSpan: 2},
            {value: '原厂/兼容', colSpan: 3},
            {value: '数量（单位）', colSpan: 4},
            {value: '单价（元）', colSpan: 2},
            {value: '金额（元）', colSpan: 2},
          ],
        },
      );

      this.form.itemList.forEach((i, index) => {
        content.rows.push({
          cols: [
            {value: index + 1, colSpan: 3},
            {value: i.name, colSpan: 3},
            {value: i.operationType, colSpan: 2},
            {value: i.compatibilityRequirement, colSpan: 3},
            {value: `${i.quantity}${i.unitName}` || '', colSpan: 4},
            {value: i.price, colSpan: 2},
            {value: i.money, colSpan: 2},
          ],
        });
      });

      content.rows.push(
        {
          cols: [
            {value: '报价合计', colSpan: 19, style: { fontWeight: 'bold', background: '#f5f5f5' }},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '序号', colSpan: 3},
            {value: '计费项', colSpan: 3},
            {value: '金额（元）', colSpan: 3},
            {value: '合计金额（元）', colSpan: 5},
            {value: '优惠后金额（元）', colSpan: 5},
          ],
        },
      );

      const total = this.billData[0].total ? '¥' + this.formatNumberWithCommasAndTwoDecimals(this.billData[0].total) : '';
      const totalChinese = this.billData[0].total ? numberToChinese(this.billData[0].total) : '';

      content.rows.push(
        {
          cols: [
            {value: '1', colSpan: 3},
            {value: '人工', colSpan: 3},
            {value: this.formatNumberWithCommasAndTwoDecimals(this.billData[0].money), colSpan: 3},
            {value: total ? `${total}\r\n(大写)${totalChinese}` : '', rowSpan: 3, colSpan: 5, style: { fontWeight: 'bold' }},
            {value: total ? `${total}\r\n(大写)${totalChinese}` : '', rowSpan: 3, colSpan: 5, style: { fontWeight: 'bold' }},
          ],
        },
        {
          cols: [
            {value: '2', colSpan: 3},
            {value: '零配件', colSpan: 3},
            {value: this.formatNumberWithCommasAndTwoDecimals(this.billData[1].money), colSpan: 3},
          ],
        },
        {
          cols: [
            {value: '3', colSpan: 3},
            {value: '运费及其它', colSpan: 3},
            {value: this.formatNumberWithCommasAndTwoDecimals(this.billData[2].money), colSpan: 3},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '说明', colSpan: 3, style: { padding: '1em 0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {
              value: [
                `1、修理过程中如发现还需要更换的其它配件时，需及时通知${this.orderDetail.inquirePriceParty || '____'}，得到${this.orderDetail.inquirePriceParty || '____'}同意后，继续进行修理。`,
                `2、保修期为修理品发货后${this.form.warrantyPeriod || '____'}${(this.form.warrantyPeriodUnitName === '月' ? '个月' : this.form.warrantyPeriodUnitName) || ''}，开具${this.form.taxRate ? this.form.taxRate : '___'}，${this.form.invoiceType ? InvoiceTypeEnumMap[this.form.invoiceType] : '____'}。`,
                '3、本报价单一式贰份，各方各存一份，且同等效力，双方签字盖章生效（传真件有效）。',
              ],
              colSpan: 16,
              tdStyle: {
                padding: '8px 6px',
              },
            },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {
              type: 'footer',
              value: [
                {
                  label: '报价单位：',
                  content: this.userInfo.tenantName,
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                  },
                },
                {
                  label: '授权代表（签字）：',
                  content: '',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                },
                {
                  label: '日期：',
                  content: '年月日',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                    letterSpacing: '4em',
                    marginLeft: '4em',
                  },
                },
              ],
              colSpan: 19,
              style: {
                width: '30em',
                display: 'inline-block',
                textAlign: 'left',
                margin: '1em 0',
              },
              tdStyle: {
                textAlign: 'right',
              },
            },
          ],
        },
      );

      this.printConfig.contentConfig = [content];
    },
    handlePreview () {
      this.handlePrintInfo();

      this.printDialogVisible = true;
    },
    handleDownloadData (rows) {
      (rows || []).forEach(row => {
        if (['维修', '测试'].includes(row.operationType)) {
          row.code = '';
          row.name = '';
          row.model = '';
          row.quantity = 1;
          row.unitName = '次';
          row.compatibilityRequirement = '';
        }
      });
    },
    importByExcel () {
      this.isImportByExcel = true;
    },
    cancelExcel () {
      this.isImportByExcel = false;
    },
    confirmExcel (excelData) {
      return new Promise(resolve => {
        this.isImportByExcel = false;
        resolve();
      }).then(() => {
        this.form.itemList = [...this.form.itemList, ...excelData];

        this.calcBill();
      });
    },
    handleMoneyChange () {
      this.billData[0].total = ((this.billData[0].money || 0) + (this.billData[1].money || 0) + (this.billData[2].money || 0)) || '';
    },
    calcBill () {
      let fee0 = 0;
      let fee1 = 0;

      this.form.itemList.forEach(item => {
        if (item.operationType === '更换') { // 计入备件费用
          fee1 += item.quantity && item.price ? parseInt((item.quantity * 100) * (item.price * 100)) / 10000 : 0;
        } else { // 计入人工费用
          fee0 += item.quantity && item.price ? parseInt((item.quantity * 100) * (item.price * 100)) / 10000 : 0;
        }
      });

      this.billData[0].money = fee0 || '';
      this.billData[1].money = fee1 || '';

      this.billData[0].total = ((this.billData[0].money || 0) + (this.billData[1].money || 0) + (this.billData[2].money || 0)) || '';
    },
    handleClose () {
      this.$emit('update:dialogVisible', false);
    },
    async save (draftFlag) {
      const api = this.form.status ? inquireQuotePriceUpdate : inquireQuotePriceAdd;

      const params = {
        ...this.form,
        inquireQuotePriceAnnexList: (this.form.inquireQuotePriceAnnexList || []).map(i => {
          return {
            ...i,
            annexName: i.annexName ? i.annexName : i.name ? i.name.substr(i.name.lastIndexOf('/') + 1) : undefined,
          };
        }),
        taxRate: this.form.taxRate.replace('%', '') / 100,
        inquirePriceId: this.id,
        draftFlag,
        labourAmount: this.billData[0].money,
        sparePartAmount: this.billData[1].money,
        transportationAmount: this.billData[2].money,
        totalAmount: this.billData[0].total,
        itemList: this.form.itemList.map(item => ({
          ...item,
          operationType: operationTypeMap[item.operationType],
          compatibilityRequirement: compatibilityRequirementMap[item.compatibilityRequirement],
        })),
      };

      await api(params);

      this.$message.success('操作成功');

      this.handleClose();
      this.$emit('callback');
    },
    handleSave (draftFlag) {
      if (draftFlag) {
        this.save(draftFlag);
      } else {
        if (draftFlag === false && !this.form.itemList?.length) {
          this.$message.warning('报价明细不能为空！');
          return false;
        }

        this.$refs.form.validate(async valid => {
          if (valid) {
          // 新增状态下不存在status字段
            this.save(draftFlag);
          }
        });
      }
    },
    async getDetail () {
      const { body } = await inquireQuotePriceNewestDetail({
        findDraftFlag: ['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(this.status),
        inquirePriceId: this.id,
      });

      if (body) {
        body.taxRate = (body.taxRate || body.taxRate === 0) ? body.taxRate * 100 + '%' : '';

        const itemList = (body.itemList || []).map(item => ({
          ...item,
          money: item.quantity && item.price ? parseInt((item.quantity * 100) * (item.price * 100)) / 10000 : '',
          operationType: operationTypeMap[item.operationType],
          compatibilityRequirement: compatibilityRequirementMap[item.compatibilityRequirement],
        }));

        this.form = {
          ...body,
          inquireQuotePriceAnnexList: (body.inquireQuotePriceAnnexList || []).map(i => ({
            ...i,
            attachName: i.annexName,
            name: i.annexName,
          })),
          itemList,
        };

        this.billData[0].money = body.labourAmount || '';
        this.billData[1].money = body.sparePartAmount || '';
        this.billData[2].money = body.transportationAmount || undefined;

        this.billData[0].total = ((this.billData[0].money || 0) + (this.billData[1].money || 0) + (this.billData[2].money || 0)) || '';
      }
    },
    handleRemove (file) {
      const index = this.form.inquireQuotePriceAnnexList.findIndex(v => v.url === file.url);
      this.form.inquireQuotePriceAnnexList.splice(index, 1);
    },
    async getTaxRateDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'TAX_RATE',
      });

      this.taxRateOptions = body;
    },
    async getUnitDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'WARRANTY_PERIOD_UNIT',
      });

      this.warrantyPeriodUnitOptions = body;
    },
    async getMeasureUnitDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'MEASURE_UNIT',
      });

      this.measureUnitOptions = body;
    },
    handleDelete (index) {
      this.form.itemList.splice(index, 1);
    },
    handleAdd () {
      this.form.itemList.splice(0, 0, {});
    },
    arraySpanMethod ({ columnIndex }) {
      if (columnIndex === 2) {
        return [3, 1];
      }
    },
  },
  mounted () {
    this.userInfo = JSON.parse(this.$local.get('userInfo'));
    this.form.contactName = this.userInfo.name;
    this.form.contactPhone = this.userInfo.phoneNumber;

    this.getTaxRateDictionaryData();
    this.getUnitDictionaryData();
    this.getMeasureUnitDictionaryData();
  },
};
</script>

<style scoped lang="scss">
.detail_dialog {
  /deep/ .el-dialog__header {
    padding: 16px 20px;
  }

  .title-box {
    color: #303133;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        &:hover {
          cursor: pointer;
        }
      }

      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  .number-input {

    /deep/ .el-input-number__decrease,
    /deep/ .el-input-number__increase {
      display: none;
    }

    /deep/ .el-input__inner {
      padding: 0 15px;
      text-align: left;
    }
  }

  .table-wrapper {
    padding-left: 16px;

    /deep/.el-table__header th {
      background-color: #F2F4F7;
    }

    /deep/ .table-label {
      color: rgba(0, 0, 0, 0.85);
      font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", 微软雅黑, Arial, sans-serif, PingFangSC-Medium, "PingFang SC";
      font-size: 14px;
      font-weight: bold;
    }
  }

  .form-box {
    .el-form-wrapper {
      flex: 1;
      overflow: auto;

      /deep/ .el-form-item {
        margin-bottom: 0;
      }
    }

    /deep/ .el-form-item.is-error .el-input__inner {
      border-color: #F56C6C !important;
    }
  }
}

.printDialog {
    /deep/ .el-dialog {
      min-width: 250mm;

      .el-dialog__body {
        width: 210mm;
        max-height: 297mm;
        margin: 1cm 2cm;
        overflow: auto;
      }
    }
  }
</style>

<style>
.quotePriceDialog__tooltip {
  max-width: 20em;
}
</style>
