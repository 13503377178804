<template>
  <div class="inquiryform-handle">
    <div class="content">
      <div class="content__main">
        <div class="content__main-flag">
          <img :src="flagSvg">
        </div>
        <div class="content__main-header">
          <div class="content__main-header-order">询单号：{{ detail.code }}</div>
          <div class="content__main-header-name">
            <template v-if="detail.releaseTenantIsPersonal">
              {{ detail.businessTypeDesc }}_{{ detail.code }}
            </template>
            <template v-else>
              {{ detail.inquirePriceParty }}_{{ detail.businessTypeDesc }}_{{ detail.code }}
            </template>
            <span v-if="detail.inquireTenantStatusDesc" :class="['c_tag', detail.inquireTenantStatus ? detail.inquireTenantStatus.toLowerCase() : '']">
              {{ detail.inquireTenantStatusDesc }}
            </span>
            <span v-if="detail.inquireTenantStatus === 'CLOSED'"
              :class="['c_reason', detail.inquireTenantStatus ? detail.inquireTenantStatus.toLowerCase() : '']">
              <i class="el-icon-warning-outline"></i>关单原因：{{ detail.closeReason }}
            </span>
          </div>
        </div>
        <el-image v-if="detail.winTheBidFlag" class="tupian" :src="zhongbiao"></el-image>
        <el-image v-else-if="detail.enteredFlag" class="tupian" :src="ruwei"></el-image>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="询单信息" name="order">
          <component :is="orderComponent" class="pane" :info="detail" :isHandle="true"></component>
        </el-tab-pane>
        <el-tab-pane label="报价信息" name="price">
          <component ref="price" :is="priceComponent" class="pane" :isQuoted.sync="isPreviewAble" :id="id" :isHandle="true"  :contactInfo="contactInfo"></component>
        </el-tab-pane>
        <el-tab-pane label="履约信息" name="contract">
          <Contract class="pane"></Contract>
        </el-tab-pane>
        <el-tab-pane label="日志动态" name="log">
          <Log ref="log" class="pane" :id="id"></Log>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="button-wrapper">
      <el-button type="primary"
        v-if="(!detail.workOrder || (detail.workOrder && !detail.workOrder.takeOrderFlag)) && detail.hasPermissionDealFlag && detail.businessType === 'MAINTAIN' && !['WAIT_TASK_ORDER', 'REJECTED'].includes(detail.inquireTenantStatus)"
        v-auth="'gongxutong:inquire_price:customer_inquire:assign_work'"
        @click="handleDispatch">派工</el-button>
      <el-button
        type="danger"
        v-if="detail.hasPermissionDealFlag && ['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(detail.inquireTenantStatus) || (['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['UPDATE', 'ROLLBACK'].includes(detail.negotiatingType)))"
        v-auth="'gongxutong:inquire_price:customer_inquire:reject'"
        plain
       @click="() => openDialog('reject')">
       拒单
      </el-button>
      <!--本期不做转单-->
      <!--<el-button type="primary" v-if="['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(detail.inquireTenantStatus) || (['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['UPDATE', 'ROLLBACK'].includes(detail.negotiatingType)))" @click="() => openDialog('transfer')">转单</el-button>-->
      <el-button
        type="primary"
        v-if="detail.hasPermissionDealFlag && ['WAIT_TASK_ORDER'].includes(detail.inquireTenantStatus)"
        v-auth="'gongxutong:inquire_price:customer_inquire:task_order'"
        @click="handleTask">
        接单
      </el-button>
      <el-button
        type="primary"
        v-if="detail.hasPermissionDealFlag && ['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['UPDATE'].includes(detail.negotiatingType))"
        v-auth="'gongxutong:inquire_price:customer_inquire:confirm_quote'"
        @click="handleConfirmQuotePrice">
        确认报价
      </el-button>
      <el-button
        type="primary"
        v-if="detail.hasPermissionDealFlag && ['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(detail.inquireTenantStatus) || (['NEGOTIATING'].includes(detail.inquireTenantStatus) && (['ROLLBACK'].includes(detail.negotiatingType)))"
        v-auth="'gongxutong:inquire_price:customer_inquire:quote'"
        @click="handleQuotePrice">
        报价
      </el-button>
      <el-button v-if="isPreviewAble" @click="handlePreview">预览报价单</el-button>
      <el-button @click="handleBack">返回</el-button>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="拒单原因" prop="rejectType">
          <el-select v-model="form.rejectType" placeholder="请选择拒单原因" clearable>
            <el-option v-for="item in rejectOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="printDialogVisible" class="printDialog" top="5vh">
      <div slot="title" style="font-family: PingFangSC, PingFang SC;font-weight: 600;font-size: 14px;color: #1F2733;">
        预览报价单
      </div>
      <PrintTemplate :config="printConfig"></PrintTemplate>
      <template #footer>
        <el-button type="primary" @click="handlePrint">打印</el-button>
      </template>
    </el-dialog>

    <el-dialog title="指派工单" :visible.sync="dialogVisibleDispatch" width="670px">
      <el-form ref="dispatchForm" :model="dispatchForm" :rules="dispatchRules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="交付方式" prop="deliveryMethod">
              <el-select v-model="dispatchForm.deliveryMethod" placeholder="请选择" clearable :disabled="!dispatchForm.edit || redesignate">
                <el-option v-for="opt in deliveryMethodOptions" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上门时间" prop="expectedArrivalDate">
              <el-date-picker v-model="dispatchForm.expectedArrivalDate" type="datetime" placeholder="请选择" :disabled="!dispatchForm.edit || redesignate">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择员工" prop="principal">
              <el-select v-model="dispatchForm.principal" @change="changeEmployee" placeholder="请选择" clearable value-key="value">
                <el-option v-for="item in employeeList" :key="item.value" :label="item.label" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="派工备注" prop="dispatchRemark">
              <el-input v-model="dispatchForm.dispatchRemark" type="textarea" :rows="6" placeholder="请输入" maxlength="200" show-word-limit :disabled="redesignate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDispatch = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmDispatch">保 存</el-button>
      </span>
    </el-dialog>
    <component ref="quotePrice" :is="quotePriceComponent" :id="id" :status="detail.inquireTenantStatus" :dialogVisible.sync="quotePriceDialogVisible"
      :sparePartList="detail.sparePartList || []" :orderDetail="detail" @callback="handleCallback"></component>
  </div>
</template>
<script>
import MaintainOrder from './detail/maintain/order.vue';
import MaintainPrice from './detail/maintain/price.vue';
import MaintainQuotePriceDialog from './detail/maintain/quotePriceDialog.vue';
import SparepartOrder from './detail/sparepart/order.vue';
import SparepartPrice from './detail/sparepart/price.vue';
import SparepartQuotePriceDialog from './detail/sparepart/quotePriceDialog.vue';
import Contract from './detail/contract.vue';
import Log from './detail/log.vue';
import { inquirePriceDetail, inquirePriceReject, inquirePriceTransfer, inquirePriceTaskOrder, inquireQuotePriceConfirm, inquireQuotePriceNewestDetail, requirePriceDispatchWork, getListByOrderSourceAndNo } from '@/api/inquiryForm.js';
import UserApi from '@/api/user';
import PrintTemplate from '@/components/printTemplate/index.vue';
import * as Moment from 'dayjs';
import { numberToChinese } from '@/utils/number';

const componentMap = {
  MAINTAIN: {
    orderComponent: MaintainOrder,
    priceComponent: MaintainPrice,
    quotePriceComponent: MaintainQuotePriceDialog,
  },
  SPARE_PART: {
    orderComponent: SparepartOrder,
    priceComponent: SparepartPrice,
    quotePriceComponent: SparepartQuotePriceDialog,
  },
};

export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    MaintainOrder,
    MaintainPrice,
    MaintainQuotePriceDialog,
    SparepartOrder,
    SparepartPrice,
    SparepartQuotePriceDialog,
    Contract,
    Log,
    PrintTemplate,
  },
  computed: {
    orderComponent () {
      return componentMap[this.type].orderComponent;
    },
    priceComponent () {
      return componentMap[this.type].priceComponent;
    },
    quotePriceComponent () {
      return componentMap[this.type].quotePriceComponent;
    },
  },
  data () {
    return {
      isPreviewAble: false,
      flagSvg: require('@/assets/document_checked.svg'),
      ruwei: require('@/assets/ruwei.png'),
      zhongbiao: require('@/assets/zhongbiao.png'),
      activeName: '',
      detail: {},
      form: {
        rejectType: '',
      },
      dialogVisible: false,
      quotePriceDialogVisible: false,
      rules: {
        rejectType: [
          { required: true, message: '请选择拒单原因', trigger: 'change' },
        ],
      },
      dispatchRules: {
        deliveryMethod: [
          { required: true, message: '请选择交付方式', trigger: 'change' },
        ],
        expectedArrivalDate: [
          { required: true, message: '请选择上门时间', trigger: 'change' },
        ],
        principal: [
          { required: true, message: '请选择员工', trigger: 'change' },
        ],
      },
      rejectOptions: [
        {
          label: '无法及时交付',
          value: 1,
        },
        {
          label: '无货/无法服务',
          value: 2,
        },
        {
          label: '单据错误',
          value: 3,
        },
        {
          label: '其它',
          value: 4,
        },
      ],
      deliveryMethodOptions: [
        {
          label: '上门',
          value: 'HOME',
        },
        {
          label: '快递',
          value: 'EXPRESS',
        },
        {
          label: '自提',
          value: 'SELF_FETCH',
        },
        {
          label: '物流',
          value: 'LOGISTICS',
        },
      ],
      employeeList: [],
      title: '',
      contactInfo: {
        name: '',
        phone: '',
      },
      printConfig: {
        printId: 'queryPrice',
        title: '报价单',
        logo: require('@/assets/logo.svg'),
        contentConfig: [],
      },
      printDialogVisible: false,
      dialogVisibleDispatch: false,
      dispatchForm: {
        deliveryMethod: '',
        expectedArrivalDate: '',
        principal: '',
        dispatchRemark: '',
      },
      principalObj: {},
      redesignate: false,
    };
  },
  methods: {
    handlePrint () {
      const style
        = '@page { margin: 0; } @media print { * { font-size: 12pt; background-color: red;} body { margin: 1cm; }}';

      this.$print({
        printable: this.printConfig.printId,
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
    handleCallback () {
      this.getDetail();
      this.$refs.price.getDetail();
      this.$refs.log.init();
    },
    getDetail () {
      inquirePriceDetail({
        id: this.id,
        findNewestQuoteFlag: true,
        findWorkOrderFlag: true,
      }).then(({ body }) => {
        this.detail = body || {};
        this.contactInfo = {
          name: body.createUserName,
          phone: body.createUserPhone,
        };
      });
    },
    formatDate (text) {
      return text ? Moment(text).format('YYYY-MM-DD') : '';
    },
    getDesc (key) {
      let ret = '';

      if (key === 'quantity') {
        ret = `${this.detail?.deviceList?.[0].quantity}${this.detail?.deviceList?.[0].unitName}` || '';
      } else {
        ret = this.detail?.deviceList?.[0][key] || '';
      }
      return ret;
    },
    formatNumberWithCommasAndTwoDecimals (num) {
      if (!num && num !== 0) {
        return '';
      }

      let numStr = num.toFixed(2);

      let [integerPart, decimalPart] = numStr.split('.');

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return integerPart + (decimalPart ? '.' + decimalPart : '');
    },
    handlePrintInfo () {
      const content = {
        type: 'table',
        colCount: 19,
        rows: [],
      };

      content.rows.push(
        {
          cols: [
            {value: '客户名称', colSpan: 3},
            {value: this.detail.inquirePriceParty, colSpan: 16},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '地址', colSpan: 3},
            {value: this.$route.name === 'RECOMMEND_BUSINESS_OPPORTUNITIES'
              ? `${this.detail.deliveryProvinceName || ''}${this.detail.deliveryCityName
          || ''}` : `${this.detail.deliveryProvinceName || ''}${this.detail.deliveryCityName
            || ''}${this.detail.deliveryAreaName || ''}${this.detail.deliveryDetailAddress || ''}`,
            colSpan: 10},
            {value: '交付日期', colSpan: 2},
            {value: this.formatDate(this.$refs.price.infos[0].plannedDeliveryDate), colSpan: 4},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '联系人', colSpan: 3},
            {value: this.$refs.price.infos[0].contactName, colSpan: 2},
            {value: '手机', colSpan: 2},
            {value: this.$refs.price.infos[0].contactPhone, colSpan: 2},
            {value: '电子邮箱', colSpan: 2},
            {value: '', colSpan: 2},
            {value: '邮编', colSpan: 2},
            {value: '', colSpan: 4 },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '产品信息', colSpan: 3, rowSpan: 2, style: { padding: '1em 0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {value: '产品名称', colSpan: 2},
            {value: this.getDesc('deviceName'), colSpan: 2},
            {value: '品牌', colSpan: 2},
            {value: this.getDesc('deviceBrandName'), colSpan: 2},
            {value: '型号', colSpan: 2},
            {value: this.getDesc('deviceModel'), colSpan: 2},
            {value: '编号', colSpan: 1},
            {value: this.getDesc('deviceSerialNumber'), colSpan: 3},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '外置附件', colSpan: 2},
            {value: '', colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '检测信息', colSpan: 3, rowSpan: 3, style: { padding: '0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {value: '主诉故障现象', colSpan: 2},
            {value: this.getDesc('faultDescription'), colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '检测结果', colSpan: 2},
            {value: this.$refs.price.infos[0].detectionResult, colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '备注', colSpan: 2},
            {value: '', colSpan: 14},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '维护维修及更换零配件情况', colSpan: 19, style: { fontWeight: 'bold', background: '#f5f5f5' }},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '序号', colSpan: 3},
            {value: '零配件名称', colSpan: 3},
            {value: '操作内容', colSpan: 2},
            {value: '原厂/兼容', colSpan: 3},
            {value: '数量(单位)', colSpan: 4},
            {value: '单价(元)', colSpan: 2},
            {value: '金额(元)', colSpan: 2},
          ],
        },
      );

      this.$refs.price.infos[0].itemList.forEach((i, index) => {
        content.rows.push({
          cols: [
            {value: index + 1, colSpan: 3},
            {value: i.name, colSpan: 3},
            {value: i.operationTypeDesc, colSpan: 2},
            {value: i.compatibilityRequirementDesc, colSpan: 3},
            {value: `${i.quantity}${i.unitName}` || '', colSpan: 4},
            {value: i.price, colSpan: 2},
            {value: i.rowAmount, colSpan: 2},
          ],
        });
      });

      content.rows.push(
        {
          cols: [
            {value: '报价合计', colSpan: 19, style: { fontWeight: 'bold', background: '#f5f5f5' }},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '序号', colSpan: 3},
            {value: '计费项', colSpan: 3},
            {value: '金额(元)', colSpan: 3},
            {value: '合计金额(元)', colSpan: 5},
            {value: '优惠后金额(元)', colSpan: 5},
          ],
        },
      );

      let total = (this.$refs.price.infos[0].labourAmount || 0) + (this.$refs.price.infos[0].sparePartAmount || 0) + (this.$refs.price.infos[0].transportationAmount || 0);
      const totalChinese = total ? numberToChinese(total) : '';
      total = total ? '¥' + this.formatNumberWithCommasAndTwoDecimals(total) : '';

      content.rows.push(
        {
          cols: [
            {value: '1', colSpan: 3},
            {value: '人工', colSpan: 3},
            {value: this.formatNumberWithCommasAndTwoDecimals(this.$refs.price.infos[0].labourAmount), colSpan: 3},
            {value: total ? `${total}\r\n(大写)${totalChinese}` : '', rowSpan: 3, colSpan: 5, style: { fontWeight: 'bold' }},
            {value: total ? `${total}\r\n(大写)${totalChinese}` : '', rowSpan: 3, colSpan: 5, style: { fontWeight: 'bold' }},
          ],
        },
        {
          cols: [
            {value: '2', colSpan: 3},
            {value: '零配件', colSpan: 3},
            {value: this.formatNumberWithCommasAndTwoDecimals(this.$refs.price.infos[0].sparePartAmount), colSpan: 3},
          ],
        },
        {
          cols: [
            {value: '2', colSpan: 3},
            {value: '运费及其它', colSpan: 3},
            {value: this.formatNumberWithCommasAndTwoDecimals(this.$refs.price.infos[0].transportationAmount), colSpan: 3},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '说明', colSpan: 3, style: { padding: '1em 0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {
              value: [
                `1、修理过程中如发现还需要更换的其它配件时，需及时通知${this.detail.inquirePriceParty || '____'}，得到${this.detail.inquirePriceParty || '____'}同意后，继续进行修理。`,
                `2、保修期为修理品发货后${this.$refs.price.infos[0].warrantyPeriod || '____'}${(this.$refs.price.infos[0].warrantyPeriodUnitName === '月' ? '个月' : this.$refs.price.infos[0].warrantyPeriodUnitName) || ''}，开具${this.$refs.price.infos[0].taxRate ? this.$refs.price.infos[0].taxRate * 100 + '%' : '___'}，${this.$refs.price.infos[0].invoiceTypeDesc || '____'}。`,
                '3、本报价单一式贰份，各方各存一份，且同等效力，双方签字盖章生效(传真件有效)。',
              ],
              colSpan: 16,
              tdStyle: {
                padding: '8px 6px',
              },
            },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {
              type: 'footer',
              value: [
                {
                  label: '报价单位：',
                  content: this.$refs.price.infos[0].tenantName,
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                  },
                },
                {
                  label: '授权代表(签字)：',
                  content: '',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                },
                {
                  label: '日期：',
                  content: '年月日',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                    letterSpacing: '4em',
                    marginLeft: '4em',
                  },
                },
              ],
              colSpan: 19,
              style: {
                width: '30em',
                display: 'inline-block',
                textAlign: 'left',
                margin: '1em 0',
              },
              tdStyle: {
                textAlign: 'right',
              },
            },
          ],
        },
      );

      this.printConfig.contentConfig = [content];
    },
    handlePrintSparepartInfo () {
      const content = {
        type: 'table',
        colCount: 11,
        rows: [],
      };

      content.rows.push(
        {
          cols: [
            {value: '客户名称'},
            {value: this.detail.inquirePriceParty, colSpan: 10},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '地址'},
            {value: this.$route.name === 'RECOMMEND_BUSINESS_OPPORTUNITIES'
              ? `${this.detail.deliveryProvinceName || ''}${this.detail.deliveryCityName
          || ''}` : `${this.detail.deliveryProvinceName || ''}${this.detail.deliveryCityName
            || ''}${this.detail.deliveryAreaName || ''}${this.detail.deliveryDetailAddress || ''}`,
            colSpan: 7},
            {value: '交付方式'},
            {value: this.$refs.price.infos[0].deliveryMethodDesc, colSpan: 2},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '联系人'},
            {value: this.$refs.price.infos[0].contactName},
            {value: '手机'},
            {value: this.$refs.price.infos[0].contactPhone, colSpan: 2},
            {value: '电子邮箱'},
            {value: '', colSpan: 2},
            {value: '邮编'},
            {value: '', colSpan: 2 },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '备件清单', colSpan: 11, style: { fontWeight: 'bold', background: '#f5f5f5' }},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '序号'},
            {value: '备件名称'},
            {value: '备件型号'},
            {value: '备件规格'},
            {value: '兼容性'},
            {value: '质保期'},
            {value: '数量(单位)'},
            {value: '含税单价(元)'},
            {value: '金额(元)'},
            {value: '交货时间'},
            {value: '商品编码'},
          ],
        },
      );

      this.$refs.price.infos[0].itemList.forEach((i, index) => {
        if (index !== this.$refs.price.infos[0].itemList.length - 1) {
          content.rows.push({
            cols: [
              {value: index + 1},
              {value: i.name},
              {value: i.model},
              {value: i.spec},
              {value: i.compatibilityRequirementDesc},
              {value: `${i.qualityAssPeriod || ''}${i.qualityAssPeriodUnitName || ''}` || ''},
              {value: `${i.quantity || ''}${i.unitName || ''}` || ''},
              {value: i.price},
              {value: i.rowAmount},
              {value: this.formatDate(i.plannedDeliveryDate)},
              {value: i.code},
            ],
          });
        } else {
          content.rows.push({
            cols: [
              {value: i.name},
              {value: ''},
              {value: ''},
              {value: ''},
              {value: ''},
              {value: ''},
              {value: i.quantity || ''},
              {value: ''},
              {value: i.rowAmount},
              {value: ''},
              {value: ''},
            ],
          });
        }
      });

      content.rows.push(
        {
          cols: [
            {value: '说明', colSpan: 1, style: { padding: '1em 0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {
              value: [
                `1、开具${this.$refs.price.infos[0].invoiceTypeDesc || '____'}。`,
                '3、本报价单一式贰份，各方个存一份，且同等效力，双方签字盖章生效(传真件有效)。',
              ],
              colSpan: 10,
              tdStyle: {
                padding: '8px 6px',
              },
            },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {
              type: 'footer',
              value: [
                {
                  label: '报价单位：',
                  content: this.$refs.price.infos[0].tenantName,
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                  },
                },
                {
                  label: '授权代表(签字)：',
                  content: '',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                },
                {
                  label: '日期：',
                  content: '年月日',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                    letterSpacing: '4em',
                    marginLeft: '4em',
                  },
                },
              ],
              colSpan: 11,
              style: {
                width: `${10 + (this.$refs.price.infos[0].tenantName.length < 20 ? 20 : this.$refs.price.infos[0].tenantName.length)}em`,
                display: 'inline-block',
                textAlign: 'left',
                margin: '1em 0',
              },
              tdStyle: {
                textAlign: 'right',
              },
            },
          ],
        },
      );

      this.printConfig.contentConfig = [content];
    },
    openDialog (type) {
      if (type === 'reject') {
        this.title = '拒单';
      }

      this.form = this.$options.data().form;
      this.dialogVisible = true;
    },
    handleBack () {
      this.$router.back();
    },
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleReject();
        }
      });
    },
    changeEmployee (employee) {
      this.principalObj = employee;
    },
    async handleDispatch () {
      this.redesignate = false;
      const { body } = await getListByOrderSourceAndNo({
        orderSource: 'GXT_INQUIRE_PRICE',
        sourceNo: this.detail.code,
      });
      if (body?.length) {
        const expectedTimes0 = body[0].expectedTimes0 ? JSON.parse(body[0].expectedTimes0) : [];
        this.dispatchForm.deliveryMethod = body[0].deliveryMethod;
        this.dispatchForm.expectedArrivalDate = body[0].expectedTime0 && expectedTimes0.length ? new Date(`${body[0].expectedTime0} ${expectedTimes0[0]}`).getTime() : '';
        this.dispatchForm.dispatchRemark = body[0].dispatchRemark;
        this.principalObj = {
          label: body[0].principalMsg.name,
          value: body[0].principalMsg.id,
          departmentId: body[0].principalMsg.departmentId,
        };
        this.dispatchForm.principal = this.principalObj;
        this.redesignate = true;
      } else if (!['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(this.detail.inquireTenantStatus)) {
        // 报过价的，取报价信息
        const data = await inquireQuotePriceNewestDetail({
          findDraftFlag: false,
          inquirePriceId: this.id,
        });
        if (data?.body) {
          this.dispatchForm.deliveryMethod = data.body.deliveryMethod;
          this.dispatchForm.expectedArrivalDate = data.body.plannedDeliveryDate;
          this.dispatchForm.edit = false;
        } else {
          // 没报过价的，取询单信息
          this.dispatchForm.deliveryMethod = this.detail.deliveryMethod;
          this.dispatchForm.expectedArrivalDate = this.detail.expectedDeliveryDate;
          this.dispatchForm.edit = true;
        }
      } else {
        // 没报过价的，取询单信息
        this.dispatchForm.deliveryMethod = this.detail.deliveryMethod;
        this.dispatchForm.expectedArrivalDate = this.detail.expectedDeliveryDate;
        this.dispatchForm.edit = true;
      }

      UserApi.getUserList({
        orderBy: [{
          orderByProperty: 'createTime',
          orderByType: 'ASC',
        }],
        pageFlag: false,
      }).then(res => {
        this.employeeList = (res.body.list || []).map(item => {
          return {
            label: item.name,
            value: item.id,
            departmentId: item.departmentId,
          };
        });
      });
      this.dialogVisibleDispatch = true;
    },
    handleConfirmDispatch () {
      this.$refs.dispatchForm.validate(async valid => {
        if (valid) {
          const params = {
            createWay: 'TEN_PC_MANUAL',
            customizeBusinessTypeCode: 'REPAIR_WORK_ORDER',
            inquirePriceId: this.id,
            deliveryMethod: this.dispatchForm.deliveryMethod,
            designateFlag: true,
            principalDepartmentId: this.principalObj.departmentId,
            principalId: this.principalObj.value,
            principalName: this.principalObj.label,
            dispatchRemark: this.dispatchForm.dispatchRemark,
          };
          if (typeof this.dispatchForm.expectedArrivalDate !== 'number') {
            params.expectedArrivalDate = new Date(this.dispatchForm.expectedArrivalDate).getTime();
          } else {
            params.expectedArrivalDate = this.dispatchForm.expectedArrivalDate;
          }
          await requirePriceDispatchWork(params);
          this.$message.success('提交成功，可至医修保-工单中心查看工单');
          this.dialogVisibleDispatch = false;
          this.handleCallback();
        }
      });
    },
    async handleReject () {
      await inquirePriceReject({
        id: this.id,
        rejectType: this.form.rejectType,
      });

      this.$message.success('拒单成功');
      this.getDetail();
    },
    handleTransfer () {
      inquirePriceTransfer();
    },
    async handleTask () {
      await inquirePriceTaskOrder({ id: this.id });

      this.$message.success('接单成功');
      this.getDetail();
    },
    handleQuotePrice () {
      this.quotePriceDialogVisible = true;

      this.$nextTick(() => {
        this.$refs.quotePrice.getDetail();
      });
    },
    async handleConfirmQuotePrice () {
      const { body } = await inquireQuotePriceNewestDetail({
        findDraftFlag: false,
        inquirePriceId: this.id,
      });

      await inquireQuotePriceConfirm({ id: body.id });

      this.$message.success('确认报价成功');
      this.getDetail();
    },
    handlePreview () {
      if (this.type === 'MAINTAIN') {
        this.handlePrintInfo();
      } else if (this.type === 'SPARE_PART') {
        this.handlePrintSparepartInfo();
      }

      this.printDialogVisible = true;
    },
  },
  mounted () {
    this.activeName = this.$route.query.toTab || 'order';
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
.inquiryform-handle {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content__main {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 18px 18px 0;

    .content__main-header {
      margin-left: 12px;

      .content__main-header-order {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }

      .content__main-header-name {
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: 600;

        .c_tag {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
        }

        .c_reason {
          font-size: 12px;
          color: #FAB007;
          margin-left: 8px;
        }

        .wait_task_order {
          background-color: #FEFAE0;
          color: #FAB007;
        }

        .wait_quote_price {
          background-color: #FEFAE0;
          color: #FAB007;
        }

        .quoted {
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .negotiating {
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .entered {
          background-color: #E5FEEB;
          color: #14CA64;
        }

        .rejected {
          background-color: #FEE9E5;
          color: #FF5959;
        }

        .closed {
          background-color: #F2F4F7;
          color: #1F2733;
        }
      }
    }

    .tupian {
      position: absolute;
      top: 20px;
      right: 60px;
      width: 56px;
      height: 56px;
    }
  }

  /deep/ .el-tabs__header {
    background-color: #fff;
    padding: 0 18px 0;
    margin: 0;
  }

  .pane {
    height: calc(100vh - 316px);
    overflow: auto;
  }

  .button-wrapper {
    margin-top: 8px;
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .printDialog {
    /deep/ .el-dialog {
      min-width: 250mm;

      .el-dialog__body {
        width: 210mm;
        max-height: 297mm;
        margin: 1cm 2cm;
        overflow: auto;
      }
    }
  }
}
</style>
